import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M3880 6979 c-76 -13 -205 -77 -266 -132 -73 -67 -123 -176 -131 -289
-23 -323 222 -753 702 -1234 247 -248 500 -465 705 -607 14 -10 50 -35 80 -57
144 -106 447 -300 579 -371 24 -13 66 -37 95 -52 82 -45 303 -157 311 -157 3
0 34 -13 68 -29 211 -97 485 -189 692 -231 22 -5 97 -13 168 -20 336 -30 558
60 658 267 32 66 33 73 36 203 2 129 1 139 -31 235 -35 106 -99 241 -162 341
-89 140 -106 164 -114 164 -5 0 -15 -33 -24 -72 -46 -214 -58 -261 -68 -284
-9 -20 -8 -32 10 -67 71 -143 92 -213 92 -309 0 -68 -3 -80 -26 -108 -52 -61
-70 -65 -269 -64 -195 1 -236 7 -435 67 -196 59 -368 127 -555 219 -116 56
-300 154 -345 182 -19 13 -64 40 -100 61 -95 56 -437 284 -471 314 -8 7 -59
45 -113 86 -329 247 -661 567 -883 852 -226 290 -343 568 -293 698 14 38 58
73 114 90 60 18 229 20 337 4 l76 -12 84 60 c73 53 146 97 226 137 29 14 29
22 1 30 -141 40 -299 77 -368 86 -100 12 -302 11 -380 -1z"/>
<path d="M5275 6861 c-277 -27 -527 -118 -770 -279 -159 -106 -450 -409 -428
-446 28 -45 118 -166 124 -166 4 0 16 16 28 35 l20 35 171 0 c93 0 170 -2 170
-5 0 -2 -18 -50 -39 -107 -22 -57 -50 -140 -62 -186 l-22 -83 83 -84 c46 -46
87 -82 91 -80 4 3 12 35 19 72 17 91 80 281 129 387 l40 86 250 0 251 0 -2
-357 c-3 -310 -5 -358 -18 -359 -8 -1 -118 -2 -245 -3 l-230 -2 35 -29 c19
-17 79 -64 132 -105 l96 -75 116 0 116 0 0 -84 0 -84 58 -38 c31 -20 76 -50
99 -66 23 -15 44 -28 47 -28 3 0 6 68 6 150 l0 150 350 0 350 0 0 -27 c-1 -40
-26 -209 -40 -268 -13 -56 -61 -202 -86 -265 -20 -50 -12 -62 61 -89 29 -11
73 -29 97 -40 72 -34 267 -100 387 -131 63 -17 115 -30 117 -30 8 0 102 150
126 200 89 184 123 284 158 459 26 134 29 453 4 582 -32 170 -73 298 -140 434
-211 431 -591 750 -1044 875 -169 46 -425 68 -605 51z m55 -431 l0 -180 -175
0 c-140 0 -175 3 -175 13 0 37 301 346 338 347 9 0 12 -43 12 -180z m274 146
c101 -83 296 -290 296 -315 0 -8 -46 -11 -177 -9 l-178 3 -3 178 c-2 130 1
177 9 177 7 0 30 -15 53 -34z m-683 -43 c-90 -108 -140 -172 -163 -209 -48
-75 -46 -74 -184 -74 -68 0 -124 4 -124 8 0 22 164 152 255 202 33 18 65 36
70 40 16 12 149 68 163 69 8 1 1 -15 -17 -36z m1085 9 c116 -48 256 -134 341
-209 12 -11 33 -28 47 -38 51 -38 35 -45 -101 -45 l-128 1 -45 67 c-24 37 -57
83 -73 102 -15 19 -37 46 -49 60 -11 14 -34 40 -51 58 -38 39 -29 40 59 4z
m72 -572 c40 -79 105 -270 125 -365 22 -102 41 -251 34 -269 -5 -13 -50 -15
-335 -16 -181 0 -337 2 -346 6 -14 6 -16 41 -16 360 l0 354 253 -2 252 -3 33
-65z m572 24 c44 -66 119 -217 147 -299 31 -88 62 -238 65 -313 l3 -57 -205 0
-204 0 -17 141 c-22 183 -68 348 -156 562 -4 9 33 12 165 12 l171 0 31 -46z
m220 -904 c0 -114 -72 -365 -150 -520 -64 -129 -46 -120 -242 -120 -191 0
-186 -3 -147 85 42 94 90 290 107 433 l17 142 207 0 c202 0 208 -1 208 -20z"/>
<path d="M3801 5431 c-14 -87 -14 -348 -1 -427 26 -143 43 -223 61 -274 129
-382 322 -640 666 -890 64 -46 284 -154 358 -175 33 -10 77 -24 98 -31 78 -29
119 -36 315 -55 289 -28 593 27 847 152 61 30 109 59 108 64 0 6 -32 22 -70
36 -37 15 -91 36 -120 48 -48 19 -55 19 -87 6 -46 -20 -48 -19 -34 10 10 21 8
28 -8 40 -10 8 -24 15 -30 15 -7 0 -38 14 -69 30 -31 17 -60 30 -64 30 -3 0
-27 -20 -52 -44 -65 -61 -158 -136 -169 -136 -5 0 -11 66 -12 152 l-3 151 -85
50 c-182 105 -395 241 -405 257 -4 6 -54 10 -126 10 -99 0 -119 3 -119 15 0 8
-4 15 -10 15 -5 0 -10 6 -10 13 0 6 -16 50 -34 96 -37 91 -21 75 -243 251 -24
18 -45 31 -48 28 -9 -8 31 -169 69 -279 20 -58 36 -113 36 -122 0 -15 -18 -17
-169 -17 l-170 0 -34 58 c-52 86 -94 184 -132 310 -27 90 -55 233 -55 284 0 5
38 8 84 8 47 0 87 4 90 9 3 4 -16 29 -43 54 -26 25 -82 83 -124 129 -42 45
-97 105 -122 132 -25 27 -45 53 -45 58 0 4 -6 8 -14 8 -9 0 -17 -23 -25 -69z
m896 -1218 c78 -127 81 -132 181 -256 35 -43 62 -80 60 -82 -7 -7 -109 35
-178 73 -144 79 -360 247 -360 280 0 9 35 12 140 12 l140 0 17 -27z m633 -184
l0 -210 -32 22 c-84 57 -358 355 -358 388 0 8 62 11 195 11 l195 0 0 -211z"/>
<path d="M3207 3196 c-62 -23 -108 -65 -135 -124 -48 -105 -29 -266 41 -340
54 -59 180 -90 283 -71 145 27 213 104 214 242 l0 67 -151 0 -150 0 3 -52 3
-53 63 -3 c72 -3 82 -20 30 -53 -47 -29 -129 -22 -166 14 -22 23 -27 37 -30
95 -3 50 0 78 12 100 39 76 156 84 219 17 l24 -27 62 31 c33 17 61 35 61 39 0
24 -57 84 -105 110 -41 22 -225 28 -278 8z"/>
<path d="M3715 3197 c-3 -7 -4 -129 -3 -272 l3 -260 208 -3 207 -2 0 64 0 65
-127 3 -128 3 -3 208 -2 207 -76 0 c-51 0 -76 -4 -79 -13z"/>
<path d="M4330 3201 c-106 -34 -164 -110 -177 -231 -8 -78 12 -162 50 -212 40
-52 74 -73 157 -94 56 -15 75 -15 140 -4 94 15 142 40 179 90 41 57 55 116 49
213 -8 119 -42 178 -128 223 -39 20 -223 30 -270 15z m181 -137 c55 -28 77
-132 44 -210 -31 -74 -159 -86 -215 -20 -35 44 -31 166 8 210 34 38 111 48
163 20z"/>
<path d="M4820 2935 l0 -277 163 4 c136 3 168 7 201 23 55 28 78 62 79 121 2
62 -16 105 -53 127 l-29 18 31 28 c29 26 32 34 32 83 -1 63 -22 101 -68 126
-25 13 -65 18 -193 20 l-163 4 0 -277z m248 133 c16 -16 15 -57 -2 -64 -7 -3
-29 -6 -47 -7 -33 -2 -34 -1 -37 41 l-3 42 38 0 c22 0 44 -5 51 -12z m24 -194
c4 -3 8 -18 8 -34 0 -36 -23 -50 -79 -50 l-41 0 0 44 c0 26 5 47 13 50 13 6
85 -1 99 -10z"/>
<path d="M5499 3196 c-5 -6 -9 -13 -9 -17 0 -4 -14 -38 -31 -76 -54 -119 -66
-146 -113 -254 -25 -58 -53 -119 -61 -135 -26 -51 -22 -54 68 -54 l82 0 18 40
17 40 104 0 104 0 16 -40 16 -40 85 0 c47 0 85 2 85 4 0 5 -81 192 -137 316
-14 30 -34 75 -45 100 -10 25 -26 62 -35 83 -9 20 -24 37 -32 38 -102 4 -123
3 -132 -5z m108 -265 c11 -33 19 -62 15 -65 -8 -9 -102 -7 -102 1 0 20 46 123
55 123 5 0 20 -27 32 -59z"/>
<path d="M5915 3197 c-3 -7 -4 -129 -3 -272 l3 -260 210 0 210 0 2 63 2 62
-129 0 -130 0 0 210 0 210 -80 0 c-56 0 -82 -4 -85 -13z"/>
<path d="M6455 3196 c-50 -22 -83 -69 -90 -130 -13 -105 38 -158 192 -195 45
-11 87 -22 93 -26 16 -10 12 -42 -6 -49 -31 -12 -110 -5 -164 14 -30 11 -58
19 -62 20 -4 0 -19 -23 -34 -52 l-26 -51 23 -18 c41 -30 110 -44 224 -44 92 0
116 3 145 20 47 28 70 76 70 149 0 103 -35 131 -217 171 -73 17 -94 35 -72 61
15 18 77 18 152 -2 60 -15 61 -15 79 8 9 13 23 36 29 52 11 25 10 30 -7 43
-55 40 -261 59 -329 29z"/>
<path d="M6897 3203 c-10 -10 -8 -288 3 -362 18 -127 88 -182 231 -182 94 0
146 14 192 52 51 42 61 92 62 309 l0 185 -77 3 -78 3 0 -169 c0 -222 -11 -252
-92 -252 -75 0 -87 37 -88 263 l0 157 -73 0 c-41 0 -77 -3 -80 -7z"/>
<path d="M7510 2934 l0 -277 168 5 c169 5 208 13 249 53 21 21 37 97 29 139
-7 37 -38 78 -66 88 -9 3 -6 11 13 30 34 34 44 90 26 142 -28 78 -79 95 -281
96 l-138 0 0 -276z m254 130 c35 -34 11 -64 -51 -64 -42 0 -43 1 -43 33 0 19
3 37 7 40 12 13 72 7 87 -9z m16 -192 c34 -28 21 -69 -24 -77 -61 -12 -81 -4
-84 33 -2 19 -1 40 2 48 8 21 79 18 106 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
